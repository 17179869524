<template>
    <section id="dashboard-register">
        <b-row class="title mb-2" v-if="$role(['campaigns.create'])">
            <b-col class="text-start">
                <card-header v-bind="cardHeaderOptions"/>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <campaign-table/>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {BRow, BCol} from "bootstrap-vue";
import CardHeader from "@/views/components/CardHeader.vue";
import CampaignTable from "./CampaignTable.vue";

export default {
    components: {
        BRow,
        BCol,
        CampaignTable,
        CardHeader,
    },
    data() {
        return {
            data: {},
            cardHeaderOptions: {
                title: "Gerenciar Conteúdos",
                description:
                    "Crie os conteúdos para suas campanhas de divulgação e interação.",
                icon: require("@/assets/images/icons/index.svg"),
                link: "campaigns/contents/new",
                textButton: "Criar conteúdo",
            },
        };
    },
    created() {
    },
    methods: {},
};
</script>
