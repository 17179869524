<template>
    <b-card no-body>
        <loading v-if="loading"/>
        <b-card-body>
            <common-table-custom
                :show-details="false"
                :title="isContent ? 'Conteúdos' : 'Campanhas recentes'"
                v-bind="configTableCampaign"
            >
                <template v-slot:filters>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink">
                            <select v-model="configTableCampaign.filters.order_column"
                                    class="form-control like-dropdown my-1">
                                <option :value="null" hidden selected>Filtrar por</option>
                                <option value="name">Nome</option>
                                <option value="type">Tipo</option>
                                <option value="created_at">Data de Criação</option>
                            </select>
                        </div>
                        <div class="flex-shrink">
                            <select v-model="configTableCampaign.filters.order"
                                    class="form-control like-dropdown mx-md-2 my-1">
                                <option :value="null" hidden selected>Classificar por</option>
                                <option value="asc">Ascendente</option>
                                <option value="desc">Descendente</option>
                            </select>
                        </div>
                        <div class="input-group input-group-merge search my-1 w-100 flex-grow-1" role="group"
                             style="width: 300px !important;">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <feather-icon icon="SearchIcon" size="25"/>
                                </div>
                            </div>
                            <input
                                v-model="configTableCampaign.filters.search"
                                class="form-control"
                                placeholder="Buscar campanha"
                                type="text"
                            >
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <td class="text-left">Total</td>
                    <td>{{ configTableCampaign.misc ? configTableCampaign.misc.total : 0 }}</td>
                    <td
                        :colspan="5"
                        class="text-right"
                    >
                        <b-link
                            v-if="configTableCampaign.misc && configTableCampaign.misc.current_page != configTableCampaign.misc.last_page"
                            @click="getCampaigns(configTableCampaign.misc.current_page + 1)"
                        >
                            + Mostrar mais
                        </b-link>
                    </td>
                </template>
            </common-table-custom>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardBody,
    BLink,
} from "bootstrap-vue";
import {configTableCampaign} from '../campaigns/constants';
import Loading from "@/views/components/Loading.vue";
import api from "@/api";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";

export default {
    components: {
        BCard,
        BCardBody,
        BLink,
        Loading,
        CommonTableCustom
    },
    watch: {
        "configTableCampaign.filters": {
            handler() {
                clearTimeout(this.configTableCampaign.timeout);
                this.configTableCampaign.timeout = setTimeout(this.getCampaigns, 500);
            },
            deep: true
        },
    },
    created() {
        this.getCampaigns();
    },
    computed: {
        isContent() {
            return String(this.$route.name).includes('campaigns/contents')
        }
    },
    data() {
        return {
            loading: false,
            configTableCampaign: {
                ...configTableCampaign,
                actions: [
                    {
                        label: "Duplicar",
                        name: "duplicate",
                        onClick: this.duplicateCampaign,
                        roles: ['campaigns.create']
                    },
                    {
                        label: "Editar",
                        name: "edit",
                        onClick: this.editCampaign,
                        roles: ['campaigns.update']
                    },
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.deleteCampaign,
                        roles: ['campaigns.delete']
                    },
                    {
                        label: "Exportar",
                        name: "export",
                        onClick: this.exportCampaign,
                        roles: ['capaigns.export']
                    },
                ].filter(e => this.$role(e.roles)),
                filters: {
                    order_column: !String(this.$route.name).includes('campaigns/contents') ? 'created_at' : null,
                    order: !String(this.$route.name).includes('campaigns/contents') ? 'desc' : null,
                    search: null,
                    limit: 5
                },
                timeout: null
            },
        };
    },
    methods: {
        getCampaigns(page = 1) {
            this.loading = true;
            const params = {page, ...this.configTableCampaign.filters};

            api
                .get('/campaigns', {params})
                .then(response => {
                    const {type, body, misc} = response.data;

                    if (type != 'success') return;
                    if (page > 1) this.configTableCampaign.items.push(...body);
                    else this.configTableCampaign.items = body;

                    this.configTableCampaign.misc = misc;
                })
                .finally(() => this.loading = false);
        },
        deleteCampaign(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover a campanha selecionada?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/campaigns/${id}`)
                        .then(res => {
                            const {type, message} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableCampaign.items.findIndex(e => e._id == id);
                            this.configTableCampaign.misc.total -= 1;
                            if (i >= 0) this.configTableCampaign.items.splice(i, 1);
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível remover esta campanha', 'danger');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        duplicateCampaign(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja duplicar a campanha selecionada?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Duplicar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.post(`/campaigns/${id}`)
                        .then(res => {
                            const {type, message, body} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableCampaign.items.findIndex(e => e._id == id);
                            this.configTableCampaign.items.splice(i + 1, 0, body);
                            this.configTableCampaign.misc.total += 1;
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível duplicar esta campanha', 'danger');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        editCampaign(id) {
            this.$router.push({
                name: String(this.$route.name).includes('campaigns/contents') ? 'campaigns/contents/edit' : 'campaigns/edit',
                params: {id}
            });
        },
        exportCampaign(id){
            this.$swal({
                title: 'Preparando arquivo...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    this.$swal.showLoading()
                },
            })

            api.get(`/campaigns/${id}/export`, {responseType: 'blob'})
                .then(response => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: 'application/xlsx'})
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'clientes-campanha.xlsx'
                        link.click()
                        URL.revokeObjectURL(link.href)
                        return this.$swal.close();
                    }
                    throw new Error('');
                }).catch(() => {
                    this.$swal({
                        title: "Arquivo indísponível",
                        text: "Não foi possível realizar o download do arquivo.",
                        icon: "error",
                        confirmButtonText: 'Continuar',
                        confirmButtonColor: "#317f98",
                    })
                })
        }
    }
};
</script>

<style>
</style>
