import moment from "moment";

const types = {
    interaction: "Interação",
    divulgation: "Divulgação",
}

export const configTableCampaign = {
    fields: [
        {
            key: "name",
            label: "Nome",
            sortable: false,
            sortDirection: "desc",
            class: "text-left",
        },
        {
            key: "type",
            label: "Tipo",
            sortable: false,
            formatter: value => {
                try {
                    return types[value];
                } catch (e) {
                    return 'Indefinido';
                }
            },
        },
        {
            key: "created_at",
            label: "Data de criação",
            formatter: value => {
                return moment(value).format('DD/MM/YYYY')
            },
            sortable: false,
        },
        {
            key: "actions",
            label: "",
            class: "text-right d-flex justify-content-around",
            sortable: false,
        },
    ],
    items: [],
    actions: [],
    isHeader: true,
};

export const configTableSegmentation = {
    fields: [
        {
            key: "name",
            label: "Nome",
            sortable: false,
            sortDirection: "desc",
            class: "text-left",
        },
        {
            key: "shots",
            label: "Clientes",
            sortable: false,
        },
        {
            key: "created_at",
            label: "Data de criação",
            sortable: false,
        },
        {
            key: "actions",
            label: "",
            class: "text-right d-flex justify-content-around",
            sortable: false,
        },
    ],
    items: [],
    actions: [
        {text: "Duplicar", link: ""},
        {text: "Editar", link: "editar-segmentacao"},
        {text: "Deletar", link: ""},
        {text: "Atualizar", link: ""}
    ],
    isHeader: true,
    loading: false
};

export const configTableShot = {
    fields: [
        {
            key: "name",
            label: "Campanha",
            sortable: false,
            sortDirection: "desc",
            class: "text-left",
        },
        {
            key: "shots_count",
            label: "Qtd.",
            sortable: false,
        },
        {
            key: "begin_date",
            label: "Inicia",
            sortable: false,
            formatter: value => moment(value).format('DD/MM/YYYY HH:mm')
        },
        {
            key: "finish_date",
            label: "Termina",
            sortable: false,
            formatter: value => moment(value).format('DD/MM/YYYY HH:mm')
        },
        {
            key: "status",
            label: "Status",
            sortable: false,
        },
        {
            key: "custom",
            sortable: false,
            label: "",
        },
        {
            key: "actions",
            sortable: false,
            label: "",
        },
    ],
    items: [],
    filters: {
        order_column: null,
        order: null,
        search: null,
        limit: 5
    },
    timeout: null,
};

export const customCalendarModel = {
    monday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    tuesday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    wednesday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    thursday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    friday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    saturday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
    sunday: {
        primary: {
            begin: null,
            finish: null
        },
        secondary: {
            begin: null,
            finish: null
        },
        interval: false,
        active: true
    },
};

export const datePickerPt = {
    formatLocale: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Sx', 'Sa'],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
    },
    yearFormat: 'YYYY',
    monthFormat: 'MMM',
    monthBeforeYear: false,
}
