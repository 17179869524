<template>
    <div class="common-table position-relative">
        <loading v-if="loading" />
        <!-- Open Header -->
        <div
            v-if="title || filters"
            class="w-100 d-flex justify-content-between my-4 header-custom-table flex-wrap"
        >
            <slot name="title">
                <h3
                    v-if="title"
                    class="text-blue font-weight-bolder flex-grow-1 text-left"
                >
                    {{ title }}
                </h3>
            </slot>
            <slot name="filters">
                <b-form-group v-if="filters"> </b-form-group>
            </slot>
        </div>
        <!-- Close Header -->

        <!-- Main table element -->
        <b-table
            :borderless="true"
            :fields="fields"
            :items="items"
            responsive
            show-empty
            sticky-header="500px"
            thead-tr-class="text-blue"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template #cell(social_network)="data">
                <div
                    class="d-flex flex-wrap align-items-center justify-content-center"
                >
                    <font-awesome-icon
                        v-if="data.value.whatsapp"
                        class="fa-xl text-secondary mx-1"
                        icon="fa-brands fa-whatsapp"
                    />
                </div>
            </template>

            <template #cell(score)="data">
                <b-form-rating
                    v-model="data.value"
                    class="text-center"
                    no-border
                    readonly
                    size="sm"
                    style="width: max-content; margin: 0 auto"
                ></b-form-rating>
            </template>

            <template #cell(actions)="data">
                <template v-for="action in actions">
                    <span
                        v-if="!action.if || (action.if && action.if(data.item))"
                        class="mr-1"
                        :key="action.name"
                        role="button"
                        @click.prevent="action.onClick(data.item._id)"
                    >
                        {{ action.label }}
                    </span>
                </template>

                <b-link v-if="showDetails && getDetailsData" @click="data.toggleDetails">
                    <font-awesome-icon
                        @click="data.detailsShowing ? '' : $emit('detailsCallback', data)"
                        :icon="
                            data.detailsShowing
                                ? 'fa-solid fa-caret-up'
                                : 'fa-solid fa-caret-down'
                        "
                    />
                </b-link>
                <b-link v-else-if="showDetails && !getDetailsData" @click="data.toggleDetails">
                    <font-awesome-icon
                        :icon="
                            data.detailsShowing
                                ? 'fa-solid fa-caret-up'
                                : 'fa-solid fa-caret-down'
                        "
                    />
                </b-link>
            </template>

            <template #cell(custom)="data">
                <slot name="custom" v-bind:data="data.item" />
            </template>

            <template #empty>
                <p class="m-0 text-center">Nenhum registro encontrado</p>
            </template>

            <template #row-details="data">
                <b-card>
                    <slot name="details" v-bind:data="data.item" />
                </b-card>
            </template>

            <template #custom-foot :columns="items.length">
                <slot name="footer" />
            </template>
        </b-table>
    </div>
</template>

<script>
import { BTable, BCard, BFormGroup, BLink, BFormRating } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";

export default {
    //props: ["title", "fields", "items", "columnActions", "isHeader", "desactiveHeader"],
    props: {
        title: String,
        filters: Object,
        fields: Array,
        items: Array,
        actions: Array,
        loading: Boolean,
        showDetails: {
            type: Boolean,
            default: true,
        },
        detailsCallback: {
            type: Function
        },
        getDetailsData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionsOrder: [
                { label: "Asc", code: true },
                { label: "Desc", code: false },
            ],
            sortBy: "",
            sortDesc: "",
            sortDirection: "asc",
            filter: "",
        };
    },
    watch: {
        sortColumn: {
            handler() {
                const order = {
                    order_column: this.sortBy,
                    order: this.sortDesc ? "desc" : "asc",
                };
                this.$emit("onChange", order);
            },
        },
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { label: f.label, code: f.key };
                });
        },
        sortColumn() {
            return `${this.sortBy} ${this.sortDesc ? "desc" : "asc"}`;
        },
    },
    components: {
        BTable,
        BLink,
        BCard,
        BFormRating,
        BFormGroup,
        Loading,
    },
};
</script>

<style></style>
