<template>
    <div class="custom-options">
        <b-card
            body-class="text-left"
            class="overflow-hidden h-100 p-5"
            no-body
        >
            <b-row no-gutters>
                <b-col
                    class="d-flex align-items-center justify-content-center"
                    md="4"
                >
                    <b-avatar size="15rem" variant="light">
                        <img :src="icon" class="d-block img-fluid"/>
                    </b-avatar>
                </b-col>
                <b-col class="d-flex align-items-center" md="5">
                    <b-card-body class="d-flex align-items-start flex-column">
                        <b-card-title>
                            <span class="text-blue font-weight-bold">
                                {{ title }}
                            </span>
                        </b-card-title>
                        <b-card-text class="text-black font-weight-normal text-left">
                            {{ description }}
                        </b-card-text>
                        <slot name="button">
                            <b-button
                                :to="{ name: link }"
                                class="text-truncate font-weight-bolder"
                                variant="primary"
                            >
                                {{ textButton }}
                            </b-button>
                        </slot>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BCardBody,
    BAvatar,
    BButton,
    BCol,
    BRow,
} from "bootstrap-vue";

export default {
    props: ["title", "icon", "description", "link", "textButton"],
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BCardBody,
        BAvatar,
        BButton,
        BCol,
        BRow,
    },
};
</script>

<style></style>
